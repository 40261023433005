.auth-admin-container2{
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  

    .admin-dashboard{


        width: 100vw;
        height: 95vh;
        overflow-y: scroll;

        // padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 2px 1px 34px 3px rgba(61,61,61,0.75);
-webkit-box-shadow: 2px 1px 34px 3px rgba(61,61,61,0.75);
-moz-box-shadow: 2px 1px 34px 3px rgba(61,61,61,0.75);
div{
    position: relative;
    display: flex;
    justify-content:space-between;
    align-items:center;

    div{
        div{
            display: flex;
            justify-content: center;
            align-items:center;
            flex-direction: column;
        }
        img{ 
            width: 40px;
            border-radius: 50%;
        }
    }

    h3{
        background-color: #5469d4;
        color:white;
        padding: 5px;
        border: 1px solid black;
        border-radius: 10px;
    }
}

table{
    margin-top: 20px;
    width: 85vw;
    
    button{
        padding: 5px;
    }

    color: black;
    img{
        width: 50px;
    }
}
table, tr,th, td {
  border:1px solid black;

  padding: 10px;
}
td:nth-child(1){
   width: 30vw;
}
p{
    color:black;
    font-weight: 600;
    font-size: 1.3em;
}
      
    }
  

  

}

.container-update{
    width: 95vw;
    background-color: white;
  
    overflow-y: scroll;
    margin-top: 10px;
    height: 90vh;
    padding: 40px;
    button{
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .update-top{
        display: flex;
        justify-content: space-between;
        align-items:center;
        button{
            width: 100px;
        }
    }
}

.qr-view{
    width: 95vw;
    height: 60vh;
    position: fixed;
    top: 20vh;
    background-color: rgba(0, 0, 0, 0.37);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(126, 126, 126);
    border-radius: 10px;
    #psCode{
        width: 90vw;
    }
}

.admin-view-top{
    display: flex;
    justify-content: space-between;
    align-items: center;  
    width: 70vw;
    margin-left: 15vw;
    margin-top: 20px;
    button{
        width: 30vw;
    }     
}
.qr-view2{

    width: 95vw;
    height: 70vh;
    position: fixed;
    top: 20vh;
    background-color: rgba(0, 0, 0, 0.37);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid rgb(126, 126, 126);
    border-radius: 10px;
    a{
        color:white;
        font-size: 1.5rem;
        font-weight: 500;
        margin: 20px;
        text-decoration: underline;
    }
    #psCode{
        width: 90vw;
    }
    button{
        width: 80vw;
        margin-top: 2vh;
    }
   

}