.model-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
   
    .model-container-scroll{
        height: 100vh;
        overflow-y: scroll;
        width: 100%;
        .model-top{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            background-image: url(https://res.cloudinary.com/dvr397xda/image/upload/v1675096115/meta-video/moving-formation_kdruoc.gif);
            background-size: cover;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                background-color: rgba(218, 201, 201, 0);
                img{
                    width:50px;
                    position: relative;
                    border-radius: 50%;background-color: white;
        
                }
                h4{
                    position: relative;
                    color: white;
                    
                }
                p{
                    position: relative;
                    width: 90%;
                    font-size: large;
                }
            }
         
        }
        .model-logos{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin: 30px 0px;
            .model-logos-wrap{
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3,1fr);
                grid-column-gap: 10px;
                grid-row-gap: 10px;
                div{
                    display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;

            img{
                width: 90%;
                box-shadow: 2px 4px 36px 7px rgba(0, 0, 0, 0.078);
                -webkit-box-shadow: 2px 4px 36px 7px rgba(0, 0, 0, 0.073);
                -moz-box-shadow: 2px 4px 36px 7px rgba(0, 0, 0, 0.055);
            }
                }
            }
        }
    
        .model-middle{
            position: relative;
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            h2{
                padding: 10px 0px;
            }
            .model-grid{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;
                width: 94%;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    img{
                        width: 100%;
                    }
                    h3{
                        position: relative;
                    }
                    p{
                        position: relative;
                        color: black;
                        font-size: large;
                        font-weight: bold;
                    }
                }
            }
    
    
        }
        .model-show{
            position: relative;
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            img{
                width: 94%;
            }
        }
        .model-sample{
            position: relative;
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            h2{
                padding: 10px 0px;
            }
    
            .model-sample-grid{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;
                width: 94%;
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    img{
                        width: 100%;
                        border-radius: 10px;
                        box-shadow: 2px 4px 36px 7px rgba(0,0,0,0.45);
                        -webkit-box-shadow: 2px 4px 36px 7px rgba(0,0,0,0.45);
                        -moz-box-shadow: 2px 4px 36px 7px rgba(0,0,0,0.45);
                    }
                }
            }
        }
    }



}

.container-card2{
    background-image: url(https://res.cloudinary.com/dvr397xda/image/upload/v1675100329/meta-video/pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-255379_xkccwh.jpg);
    background-size: cover;
    .canvas-wrap{
        background-image: url(https://res.cloudinary.com/dvr397xda/image/upload/v1675207406/model/sport/abstract-ga0dd6ab42_1920_iczqnh.jpg);
        background-size: cover;
    }
}