
.user-wrap{
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.774);
    display: flex;
    align-items: center;
    flex-direction: column;
    .success-wrap{
        // width: 100vw;
        margin-top: 1vh;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
       
        margin-bottom: 25vh;
        border-radius: 10px;
        #payment-success-wrap{
            width: 90vw;
            border-radius: 10px;
            padding: 5vw;
            background-color: #ffffff;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            h2{
                
                width: 80vw;
                overflow-wrap: break-word;
            }
        }
       
    }

    .user-content2{
        width: 100vw;
        background-color: rgb(189, 189, 189);
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 75vh;
        overflow-y: scroll;
        button{
            width: 80%;
            margin: 10px;
        }
        #logout{
            width:96%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;

            background-color: white;
            margin: 5px;
            border-radius: 5px;

            button{

                background-color: rgb(247, 53, 53);
            }
        }

        .user-name-wrap{
            width:96%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;

            background-color: white;
            margin: 5px;
            border-radius: 5px;
            hr{
                width: 100%;
            }
        }

        .game-video-wrap{
            width: 96%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            button{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                width: 80%;

                svg{
                    scale: 1.5;
                    color: rgb(248, 248, 248);
                }

            }
        }
        .video-wrap{
            width: 96%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            height: 40vh;
            h3{
                position: absolute;
                top: 5px;
                left: 15px;
                // background-color: rgb(65, 206, 128);
                padding:5px 10px;
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
        }
        .game-wrap{
            width: 96%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            padding: 20px;
            height: 60vh;
            h3{
                position: absolute;
                top: 5px;
                left: 5px;
                // background-color: rgb(65, 206, 128);
                // padding:5px 10px;
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
            div{
                display: grid;
                grid-template-columns:repeat(2,1fr)

            }
        }
        .charge-wrap{
            width: 96%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            padding: 20px;

            h3{
                position: absolute;
                top: 5px;
                left: 5px;
                // background-color: rgb(65, 206, 128);
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
            .price-select{                 
                width: 90%;
                padding-top: 30px;
                div{
                    display: grid;
                    grid-template-columns:repeat(4,1fr);
                    // margin-top:30px;
                }
            }
            .select-price-wrap{
                width: 90%;
                display: flex;
                justify-content:flex-end;
                flex-direction: column;
                div{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    padding-top: 0;
                    input{
                        margin-top: 0;
                    }
                }
            }
        }
        .top-wrap{
            width: 96%;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            h3{
                position: absolute;
                top: 5px;
                left: 5px;
                // background-color: rgb(65, 206, 128);
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
        }
        .ad-post-wrap{
            width: 96%;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            h3{
                position: absolute;
                top: 5px;
                left: 5px;
                // background-color: rgb(65, 206, 128);
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
        }
        .order-bill-wrap{
            width: 96%;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            h3{
                position: absolute;
                top: 5px;
                left: 5px;
                // background-color: rgb(65, 206, 128);
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
        }
        .wechat-wrap{
            width: 96%;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color:white;
            border-radius: 5px;
            position: relative;
            h3{
                position: absolute;
                top: 5px;
                left: 5px;
                // background-color: rgb(65, 206, 128);
                border-radius: 5px;
                display: flex;
                justify-content:center;
                align-items: center;
            }
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 20px;
                h4{
                    padding-top: 10px;
                }
                img{
                    width: 200px;
                }
            }
        }
 
    }

    .iframe-wrap{
        position: absolute;
        width: 98vw;
        height: 90vh;
        z-index: 10000;
        iframe{
            width: 98vw;
            height: 90vh;
            z-index: 10000;
            
        }

    }


}


@media only screen and (min-width: 768px){

    .publish-content{
        width: 96vw;
        height: 70vh;
        overflow-y: scroll;
        padding-bottom: 50px;
    }
}