form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 5vw;
  }

 .payment-wrap{
     width: 90vw;
     display: flex;
     justify-content:center;
     align-self:center;
     margin-top:5vh;

    form{
        width: 80vw;
        position:relative;
        left: 0;
        background-color: cornflowerblue;
        button{
            width:30vw;
        }
    }

 }
  
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  button:hover {
    filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
      position: relative;
      // left: 10vw;
    }
  }

  .info-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100vw;
    height: 100vh;
    background-color: rgb(199, 199, 199);

    button{
        width: 20%;
    }

  }

  .Info-container{
      position: relative;
     
      width: 94vw;
      height: 90vh;
      overflow-y: scroll;
      display: flex;
      background-color: rgb(199, 199, 199);
      align-items: center;
      flex-direction: column;
  }

  .compare-wrap{
      margin-top: 20px;
      background-color: #ffffff;
      padding: 4vw;
      padding-bottom: 10vh;
      border-radius: 10px;
      table{
          width: 80vw;
      }
      table, tr,th, td {
        border:1px solid black;
      
        padding: 10px;
      }
     td:nth-child(1){
         width: 30vw;
     }
      p{
          color:black;
          font-weight: 600;
          font-size: 1.3em;
      }
  }
  .form-container{
    margin-top: 40px;
    background-color: #ffffff;
    padding: 3vw;
    border-radius: 10px;
  }

  .form-wrap{
      width: 90vw;
      display: grid;
      grid-template-columns:repeat(2,1fr);
   
      input{
          width: 95%;
          font-size:1em;
          border-radius: 20px;
          padding-left: 10px;
      }
  }
  .combo-wrap{
      width: 90vw;
      margin-top: 40px;
      background-color: #ffffff;
      padding: 3vw;
      border-radius: 10px;
      padding-bottom: 40px;

      table{
        width: 80vw;
    }
    table, tr,th, td {
      border:1px solid black;
    
      padding: 10px;
    }
   td:nth-child(1){
       width: 40vw;
   }
   td:nth-child(3) {

        input{
            height: 3vh;
        }
   }
   p{
    color:black;
    font-weight: 600;
    font-size: 1.3em;

}

  }

.success-wrap{
    width: 100vw;
    margin-top: 5vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    margin-bottom: 25vh;
    border-radius: 10px;
    div{
        width: 90vw;
        border-radius: 10px;
        padding: 5vw;
        background-color: #ffffff;
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2{
            width: 80vw;
            overflow-wrap: break-word;
        }
    }
   
}

