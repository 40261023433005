.home-wrap{

    .ads-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        position: relative;
        margin-top: 10px;
        a{
            img{
                width: 95vw;
                height: auto;
                border-radius: 10px;
                border: 1px solid black;
            }
        }
    }


    .news-pages-container{
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 75vh;
        overflow-y: scroll;
        .news-pages-video{
            width: 94%;
            iframe{
                width: 100%;
                // height: 200px;
                padding-top: 5px;
            }
        }
        .news-pages-top{
            width: 96%;
            height: 200px;
            margin-top: 10px;
            img{
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-radius: 10px;
                
            }
        }
        .news-pages-middle{
            width: 92%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                h3{

                    text-decoration: underline;
                }
            }
        }
        .news-pages-content{
            position: relative;
            width: 92%;

            p{
                position:relative;
                color: black;
                font-size: large;
                text-align: left;
            }
        }
        .ad-phone{
            h3{
                position:relative;
                a{
                    position:relative;

                    text-decoration: underline;
                }
            }
        }
        .news-pages-title{
            position: relative;
            width: 92%;
           padding: 10px;
        }
        .news-pages-footer{
            position: relative;
            width: 85%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            h6{
                position:relative;
                color: rgb(240, 72, 43);
                font-size: large;
                border: 1px solid rgb(245, 87, 87);
                border-radius: 10px;
                padding:5px 10px;
            }
        }

        .news-page-others{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;
            .news-page-others-top{
                display: flex;justify-content: space-between;
                align-items: center;
                width: 80%;
                div{
                    display: flex;
                    align-items:center;
                    svg{
                        scale: 1.5;
                    }
                }
            }
            h3{
                font-size: 1.2rem;
            }

            .news-page-others-wrap{
                position: relative;
                width: 94%;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                grid-column-gap: 5vw;
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    img{
                        width: 90%;
                        height: 100px;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                    h4{
                        width: 80%;
                    }
                }
            }
        }
     
     
           
    }










}