$color-purple: #8B5CF6;
$color-pink: #EC4899;
$color-gray: #9CA3AF;
$color-black: #1F2937;
$card-size: 18rem;
$card-height:33rem;
.gallery-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: linear-gradient(45deg, $color-purple, $color-pink);
  font-family: 'Montserrat', sans-serif;

  .carousel {
    position: relative;
    width: $card-size;
    height: $card-height;
    perspective: 300px;
    transform-style: preserve-3d;
  }
  
  .cards-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: 
      rotateY(calc(var(--offset) * 50deg)) 
      scaleY(calc(1 + var(--abs-offset) * -0.4))
      translateZ(calc(var(--abs-offset) * -30rem))
      translateX(calc(var(--direction) * -5rem));
    filter: blur(calc(var(--abs-offset) * 1rem));
    transition: all 0.3s ease-out;
  }
  
  .card {
    width: 100%;
    height: 100%;
    padding: 5px;
    background-color: hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%));
    border-radius: 1rem;
    color: $color-gray;
    text-align: justify;
    transition: all 0.3s ease-out;
    display: flex;
    justify-content:center;
    align-items: center;
  img{
      width: auto;
      height: auto;
  }
  }
  
  .nav {
    color: white;
    font-size: 10rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;
    
    &.left {
      transform: translateX(-100%) translatey(-50%);
    }
    
    &.right {
      right: 0;
      transform: translateX(100%) translatey(-50%);
    }
  }



}


