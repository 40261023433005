.create-item{
    width: 70vw;
    background-color: white;
    position: relative;
    left: 15vw;
    top: 2vh;
    overflow-y: scroll;
    
    height: 90vh;
    padding: 30px;
    button{
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
}
.select{
    display:grid;
    grid-template-columns:repeat(5,1fr);
    input{
        z-index: 200;
    }
    div{
        z-index: 200;

    }
    margin-bottom: 45vh;
}
.a-tag{
    display: flex;
    justify-content:space-between;
    align-items:center;
    h3{
        background-color: #5469d4;
        color:white;
        padding: 5px;
        border: 1px solid black;
        border-radius: 10px;
    }
}