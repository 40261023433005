


.pop-wrap{
  position: fixed;
  z-index: 20990000;
  width: 100vw;
  height: 70vh;
  top:30vh;
  display: grid;
grid-template-columns: 3fr 2fr;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;


#art{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right top, #e578b6, #c78fe2, #95a7fd, #55bcff, #00ccf9, #00d4ed, #00dadc, #36dfc7, #3ee2b8, #50e5a5, #67e691, #7fe77a);
  .canvas-wrap{
    width: 100%;
    height: 100%;
    canvas{
        width: 100%;
        height: 100%;
    }
  }
}
#des{
  background-color: rgba(75, 73, 73, 0.705);
  width: 100%;
  height: 100%;
  padding: 3vw;
  h3{
    color: #fff;
    padding-top: 2vh;
  }
  button{
    width: 70%;
    height: 5vh;
    margin-top: 2vh;
    border-radius: 5px ;
    text-align: center;
    background-color: #1e8cf3;
    color:#fff;
    font-size:1.5em;
  }
  p{
    margin-top: 2vh;
    width: 80%;
    height: 40vh;
    font-size: 1.2em;
    overflow: scroll;

  }
  img{
    width: 5vw;
   
    position:absolute;
    top:0;
    right:0;
  }
}
h2{
  color: #fff;
}
}
#loadText{
  color:#fff;
}
#loadImage{
  width: 100px;
}

