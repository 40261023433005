.container-card{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#return-home{
    position: fixed;
    top: 2vh;
    left: 2vw;
    img{
        width: 60px;
        border-radius: 50%;
        box-shadow: 0 0 2px 0 #555;
        background-color: rgb(0, 0, 0);
    }
}
.canvas-wrap{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    canvas{
        width: 100vw;
        height: 100vh;
    }


 
}

.wrapper4{
    z-index: 100000;
    position: fixed;
    // top: 200%;
    width: 90vw;
    height: 50vh;
    left:5vw;
    background-color: rgba(49, 49, 49, 0.842);

 
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
     
    h3{
      color: #fff;
  }
  #qr{
      width: 80%;
  }
  #close{
      width: 40px;
      position: absolute;
      right: 10px;
  }
}
.wrapper{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 50vh;
   
 
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        z-index: 1000;
     
    #page-item{

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 5vh;
        height: 40vh !important;
    }
    .demoPage{

        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            width: 150px;
        }
    }
}



.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width:100vw;
        height:auto;
    }
}

@media screen and(min-width:750px) {
    .container-card{


        .wrapper{
            width: 60vw;
            height: 50vh;
            left: 20vw;
            position: fixed;
            #qr{
                width: 40%;
            }
        }
    }
   
 
}

#cd-player{
    position:fixed;
    bottom: 2vh;
    right: 5vw;
    z-index: 2500;
}

