
.container-card{
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.wrapper6{

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 80vh;
   
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgb(17, 17, 17);
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;

}

.wrapper5{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 80vh;
   
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgb(17, 17, 17);
    display: flex;
        flex-direction: column;
    
        // z-index: 1000;
        .main-menu{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin: 10px;

        }
        .click-btn{
            padding:8px;
            border-radius:10px;
            font-size:1em;
            background-color: rgb(148, 238, 238);
            white-space: nowrap;
            border: 1px solid black;

        }
        .default-btn{
            padding:8px;
            border-radius:10px;
            font-size:0.7em;
            background-color: rgb(253, 253, 253);
            white-space: nowrap;
            border: 1px solid black;

        }
        .overflow-menu{
            width: 90vw;
            height: 5vh;
            margin-left:5vw;
            overflow-x: scroll;
        
        }
        .dish-container{
            width: 96%;
            position: relative;
            left: 2%;
            height: 60vh;
            overflow-y: scroll;
            .dish-item{

                .dish-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .price-container{
                    width:96%;
                    margin-left: 2%;


                    .multi{
                        display:grid;
                        grid-template-columns: repeat(2,1fr);
                        div{
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            flex-direction: row;
                            border: 1px solid rgba(94, 94, 94, 0.719);
                            button{
                                padding: 5px;
                                background-color: rgb(70, 201, 77);
                                border-radius: 5px;
                            }
                        }
                    }
                    .single{
                        display: flex;
                        justify-content: flex-end;
                        button{
                            padding: 5px;
                            background-color: rgb(70, 201, 77);
                            border-radius: 5px;
                        }
                    }

                }



            }
        }
        .cartgory-menu{
            
            display: flex;
            justify-content: center;
            align-items:center;
            flex-direction: row;
            width: fit-content;
            button{
                margin-right: 10px;
            }

        }
       
       
        #qr{
            width: 80%;
        }
        #close{
            width: 40px;
            position: absolute;
            right: 5px;
        }
    #page-item{

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 5vh;
        height: 40vh !important;
    }
    .demoPage{

        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            width: 150px;
        }
    }
}



.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width:100vw;
        height:auto;
    }
}

.cart{
    position: fixed;
    top: 5vh;
    right: 5vw;
    button{
        width: 12vw;
        height: 12vw;
        border-radius: 50%;
        border: none;
        box-shadow: 0px 4px 39px 3px rgba(0,0,0,0.62);
-webkit-box-shadow: 0px 4px 39px 3px rgba(0,0,0,0.62);
-moz-box-shadow: 0px 4px 39px 3px rgba(0,0,0,0.62);
        img{
            width: 100%;
        }
        p{
            position: absolute;
            top: -1vh;
            right:-1vw ;
            width: 6vw;
            height: 6vw;
            color: white;
            border-radius:50%;
            background-color: red;
            text-align: center;
            display: flex;
            justify-content:center;
            align-items:center;
        }
    }
}

.cart-container{
    width: 100%;
    .cart-scroll{
        width: 90vw;
        height: 40vh;
        margin-left:5vw;
        overflow-x: scroll;
        .order-wrap{
            display: grid;
            grid-template-columns: 3fr repeat(2, 1fr);
            border-bottom: 1px solid rgb(65, 65, 65);
            div{
                button{
                    background-color: rgba(240, 66, 66, 0.877);
                    padding: 3px;
                    border-radius:10px;
                    box-shadow: 0px 4px 39px 3px rgba(0, 0, 0, 0.171);
                    -webkit-box-shadow: 0px 4px 39px 3px rgba(0, 0, 0, 0.205);
                    -moz-box-shadow: 0px 4px 39px 3px rgba(0, 0, 0, 0.212);
                }
            }
        }

    }

    .info{
        display: grid;
        grid-template-columns:repeat(2,1fr);
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            input{
                width:90%;
                border-radius: 10px;
            }
        }
    }
    .sub-btn{
        margin-top:30px;
        width: 100%;
        display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            button{
                padding:10px;
                border-radius:10px;
                background-color: rgb(139, 233, 85);
                box-shadow: 0px 4px 39px 3px rgba(0, 0, 0, 0.445);
                -webkit-box-shadow: 0px 4px 39px 3px rgba(0, 0, 0, 0.452);
                -moz-box-shadow: 0px 4px 39px 3px rgba(0, 0, 0, 0.486);
            }
    }

}