.auth-container{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;

    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(138, 138, 138);

}