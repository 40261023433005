
.container-card{
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#menu-wrap{
    width: 100vw;
    height: 100vh;
    img{
        width: 100vw;
    }
    #qr{
        width: 100vw;

    }
}
.wrapper{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 80vh;
   
    background-color: rgba(61, 65, 65, 0.342);
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        // z-index: 1000;
        a{
            text-decoration: underline;
        }
        button{
            background-color:black;

            h3{
                color: #fff;
                padding: 5px;
                font-size: 20px;
            }
        }
       
        #qr{
            width: 80%;
        }
        #close{
            width: 40px;
            position: absolute;
            right: 10px;
        }
    #page-item{

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 5vh;
        height: 40vh !important;
    }
    .demoPage{

        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            width: 150px;
        }
    }
}



.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width:100vw;
        height:auto;
    }
}

