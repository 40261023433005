
.user-wrap{
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.774);
    display: flex;
    align-items: center;
    flex-direction: column;
    .posts-wrap{
        width: 96vw;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
       
     
        .posts-container{
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 67vh;
            overflow-y: scroll;
            padding: 5px;
            .post-card{
                background-color: #ccc;
                width: 96%;
                padding:5px;
                border: 1px solid rgb(2, 2, 2);
                margin-top: 15px;
                .post-top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .post-view-wrap{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #020202b4;
                        padding: 6px;
                        border-radius: 10px;
                        img{
                            width: 30px;
                            padding: 5px;
                        }
                        p{
                            position:absolute;
                            bottom: 0;
                        }
                    }
                }
                hr{
                    padding: 2px;
                    background-color: #5cc588;
                }
                .post-mid{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                    p{
                        position: relative;
                        color:black;
                        font-size: medium;
                    }
                    .post-mid-category{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    
                }
                .posts-btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        :first-child{
                            background-color: #5cc588;
                        }
                        :last-child{
                            background-color: crimson;
                        }
                    }
                    button{
                        width: 120px;
                        padding: 2px;
                    }
                }
            }
        }

    }

    .user-content{
        width: 96vw;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        .user-btn-group{
            padding: 10px;
            width: 95vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                width: 100px;
            }
        }
 
    }

    .post-content2{
        width: 96vw;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        padding: 10px;


        .publish-content{
            width: 96%;
            height: 75vh;
            overflow-y: scroll;

            #post-submit-btn{
                margin-top: 30px;
            }

            .publish-wrap{
                display: grid;
                grid-template-columns:repeat(3,1fr);
                margin-top: 10px;
            }

           .create-content{
               width: 100%;
               height: auto;
               input{
                   margin-top: 5px;
                   height: 30px;
                   padding-left: 5px;
                   font-weight: 600;
                   width: 100%;
               }
               textarea{
                   width: 100%;
                   height: 200px;
                   padding: 2vw;
               }
               .preview{
                   width: 100%;
                   height: 120px;
                   overflow-x: scroll;
               
                   div{
                    display: flex;
                    justify-content: center;
                    align-items:center;
                    flex-direction: row;
                    width: fit-content;
                    #delete-image{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 25px;
                    }
                    img{
                        width: 90px;
                        border:1px solid black;
                        margin-left: 10px;
                    }

                   }
                 
               }

            

           } 

           .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }
          
          .switch input { 
            opacity: 0;
            width: 0;
            height: 0;
          }
          
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
          }
          
          input:checked + .slider {
            background-color: #2196F3;
          }
          
          input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
          }
          
          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          
          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }
          
          .slider.round:before {
            border-radius: 50%;
          }

        }


    }

}



@media only screen and (min-width: 768px){

    .publish-content{
        width: 96vw;
        height: 70vh;
        overflow-y: scroll;
        padding-bottom: 50px;
    }
}