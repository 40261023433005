
.container-card{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.wrapper{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 50vh;
   
    background-color: rgba(61, 65, 65, 0.342);
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        // z-index: 1000;
        h3{
            color: #fff;
        }
        #qr{
            width: 80%;
        }
        #close{
            width: 40px;
            position: absolute;
            right: 10px;
        }
    #page-item{

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 5vh;
        height: 40vh !important;
    }
    .demoPage{

        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            width: 150px;
        }
    }
}



.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width:100vw;
        height:auto;
    }
}

.bottom-icon-wrap{
    position: absolute;
    bottom:20vh;
    z-index: 2500;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    
    div{
    width: 88vw;
    display:flex;
    justify-content: space-between;
    align-items: center;
    svg{
        scale: 2.5;
        background-color: rgb(52, 211, 89);
        border-radius: 3px;
        border: 1px solid rgba(83, 83, 83, 0.194);
        padding: 2px;
        color: white;
        box-shadow: 0 8px 16px -8px rgb(0, 0, 0);
    }

    }

  
}  #save-btn{
    position: absolute;
    top: 1vh;
    right: 2vw;
    width: 150px;
    background-color: rgb(52, 211, 89);
    font-size: 1rem;
    font-weight: 500;
    z-index: 1100000;
}

@media screen and(min-width:750px) {
    .container-card{


        .wrapper{
            width: 60vw;
            height: 50vh;
            left: 20vw;
            position: fixed;
            #qr{
                width: 40%;
            }
        }
    }
   
 
}
