.StarFront-box{
    width: 100%;
    height: 100%;
    position: absolute;
    transform:rotateZ(150deg);
    bottom: 10%;
    z-index: 10;
    @keyframes meteors{
        0%{
            width: 0;
        }
        50%{
            width: 230px;
        }
       
        100%{
            width: 0px;
        }
    }
    @keyframes move{
        0%{
            transform: translateX(0)
        }
        100%{
            transform: translateX(1200px)
        }
    }

    .star{
        height: 3px;
        position: absolute;
        // border-radius: 30%;
        border-top-left-radius: 80%;
        border-top-right-radius: 30%;
        border-bottom-left-radius: 80%;
        border-bottom-right-radius: 30%;
        filter: drop-shadow(0 0 7px #ebf7f8);
        background: linear-gradient(-45deg,#c7ecee,rgba(0,0,255,0));
        animation: meteors 5s ease-in-out infinite , move 5s ease-in-out infinite;
        top: 75%;
    }

    .star:nth-child(1){
        top: 40%;
        left: 100px;
        animation-delay: 6s;
    }

    // .star:nth-child(2){
    //     top: 70%;
    //     left: 80px;
    //     animation-delay: 2s;
    // }
    // .star:nth-child(3){
    //     top: 85%;
    //     left: 40px;
    //     animation-delay: 8s;
    //     z-index: 3;
    // }
    // .star:nth-child(4){
    //     top: 50%;
    //     left: 400px;
    //     animation-delay: 3s;
    //     z-index: 3;
    // }
    // .star:nth-child(5){
    //     top: 40%;
    //     left: 50px;
    //     animation-delay: 5s;
    //     z-index: 3;
    // }
   

}