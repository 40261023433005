.auth-product-create{
    position: absolute;
    width: 90vw;
    height: 85vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items:center;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid rgb(0, 0, 0);
    .news-wrap{
        padding: 30px;
        width: 100%;
        position: relative;
      
        div{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // padding: 10px;
            // margin: 10px;
            // height: 50px;
            position: relative;
            // padding: 5px;
            input{
                // margin: 0;
                width: 70%;
                height: 50px;
            }

          
        }
       

    }
    
}