
.container-card{
   
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    #control-bt{
        position: fixed;
        width: 90vw;
        bottom: 10vh;
        left: 5vw;
        z-index: 2000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 15vw;
            height: auto;
            background-color: rgb(255, 255, 255);
            border-radius: 50%;
            box-shadow: 0 0 20px 0 rgb(0, 0, 0);

        }
    }

    #gous-bg{
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
z-index: 1;
filter: blur(8px);
    }
  .canvas-wrap{
      z-index: 100;
  }

    .wrapper3{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 50vh;
   
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        // z-index: 1000;
        h3{
            color: #fff;
        }
        #qr{
            width: 80%;
        }
        #close{
            width: 40px;
            position: absolute;
            right: 10px;
        }
  
}
}


.wrapper2{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 80vh;
   
    background-color: rgba(61, 65, 65, 0.342);
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        // z-index: 1000;
    
  
}
#page-item1{

    position: absolute;
    height: 70vh;
    width:50vw;
}
.demoPage1{

    background-color: rgb(221, 202, 183);
    color: rgb(0, 0, 0);
    border: solid 1px hsl(35, 20, 70);
    overflow: hidden; 
    // z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    height: 100%;

    h2{
        position: relative;
        margin-top: 5%;
        left: 10%;
        width: 80%;
        text-align: center;
        font-size: 18px;
    }
    h3{
        position: relative;
        margin-top: 5%;
        left: 10%;
        width: 80%;
        text-align: center; 
    }
   
    img{ 
        position: relative;
        margin-top: 20px;
        left: 10%;
        width: 80%;
        width: 150px;
    }
}

.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width:100vw;
        height:auto;
    }
}
#mp3-player{
    position: fixed;
    z-index: 2500;
    right: 1vw;
    top:1vh;
}

@media screen and(min-width:750px) {
    .container-card{

        #control-bt{
            position: fixed;
            width: 90vw;
            bottom: 10vh;
            left: 5vw;
            z-index: 2000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                width: 5vw;
                height: auto;
                background-color: rgb(255, 255, 255);
                border-radius: 50%;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

            }
        }
        .wrapper{
            width: 60vw;
            height: 80vh;
            left: 20vw;
            position: fixed;
            #qr{
                width: 40%;
            }
        }
    }
    .wrapper2{
    
   

        position: fixed;
        // top: 200%;
        width: 50vw;
        left:25vw;
        height: 80vh;
        background-color: rgba(61, 65, 65, 0.342);
        display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            align-items: center;
            // z-index: 1000;
        
      
    }
    #page-item1{
    
        position: absolute;
        height: 30vh;
        width: 100%;
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
        }
    }
    .demoPage1{
    
        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        width: 100%;

        height: 80%;
    
        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            // width: 150px;
        }
    }
   
 
}

