.book-page{
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(8,180,172,1) 0%, rgba(218,179,236,1) 29%, rgba(0, 213, 255, 0.514) 100%);

    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(20px);
    #page-item{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

    }
    .demoPage{
    
        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        z-index: 100;
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
        img{ 
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
        }
    }
}


