.scroll-wrap{
    position: absolute;
    top: 70vh;
    left: 5vw;

    width: 90vw;
    height: 25vh;
    overflow-x: scroll;
    border-radius: 10px;
    border: 3px solid rgb(121, 161, 161);



   div{


    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: row;
    width: fit-content;
    height: 25vh;
    background-color: rgba(194, 182, 182, 0.445);

    section{
        width: 230px;
        height: auto;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h6{
            font-size: 2rem;
            color: #fff;
        }

    img{
       width: 100%;
        height: auto;
        border:3px solid rgb(37, 37, 37);
        border-radius: 10px;
        box-shadow: -1px 8px 33px 5px rgba(0,0,0,0.54);
-webkit-box-shadow: -1px 8px 33px 5px rgba(0,0,0,0.54);
-moz-box-shadow: -1px 8px 33px 5px rgba(0,0,0,0.54);
    }
}
   
   }
       

       
    
}

#loadText{
    color:#fff;
}
#loadImage{
    width: 100px;
}

#pano-h2{
    position: fixed;
    top: 2vh;
    
    align-items: center;
    background-color: #fff;
    font-size: 2rem;
    left: 30vw;
    padding: 5px;
    border-radius:10px;
}