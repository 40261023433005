.footer-wrap{
    position: absolute;
    bottom: 0;
    
}
#footer-menu{
    position: absolute;
    bottom:15vh;
    right: 2vw;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 2px solid black;
    svg{
        scale: 2.4;
        color: black;
    }
}