#activity{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-color: white;
    overflow-y: scroll;
    h2{
        padding: 20px;
        text-align: center;
    }
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height:100%;
        p{
            position: relative;
            color:black;
            text-align: left;
            width: 90%;
            word-break: break-all;
            margin: 10px;
            font-size: medium;
            background-color: white;
            text-align: center;

        }

    }
   
    .image-gallery-left-nav{
        left: -45%;
    }
    .image-gallery-right-nav{
        right: -45%;
    }

    #contact-form{
        width: 100%;
        .container {
            max-width:400px;
            width:100%;
            margin:0 auto;
            position:relative;

            #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea { font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; }
        
            #contact {
                background:#F9F9F9;
                padding:25px;
                margin:50px 0;
            }
            
            #contact h3 {
                color: #F96;
                display: block;
                font-size: 30px;
                font-weight: 400;
            }
            
            #contact h4 {
                margin:5px 0 15px;
                display:block;
                font-size:13px;
            }
            
            fieldset {
                border: medium none !important;
                margin: 0 0 10px;
                min-width: 100%;
                padding: 0;
                width: 100%;
            }
            
            #contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
                width:100%;
                border:1px solid #CCC;
                background:#FFF;
                margin:0 0 5px;
                padding:10px;
            }
            
            #contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
                -webkit-transition:border-color 0.3s ease-in-out;
                -moz-transition:border-color 0.3s ease-in-out;
                transition:border-color 0.3s ease-in-out;
                border:1px solid #AAA;
            }
            
            #contact textarea {
                height:100px;
                max-width:100%;
              resize:none;
            }
            
          
            
           
        
            
            #contact input:focus, #contact textarea:focus {
                outline:0;
                border:1px solid #999;
            }
            ::-webkit-input-placeholder {
             color:#888;
            }
            :-moz-placeholder {
             color:#888;
            }
            ::-moz-placeholder {
             color:#888;
            }
            :-ms-input-placeholder {
             color:#888;
            }
        }
        
      
        
    }
}
body{
    background-color: white;
}
@import "~react-image-gallery/styles/scss/image-gallery.scss";
