#play-btn{
    position: fixed;
    top: 40vh;
    z-index: 100;
    width: 30vw;
    left: 35vw;
}
.container-card2{
    video{
        display: none;
    }
}