.auth-product3-create{
    position: absolute;
    width: 90vw;
    height: 80vh;
    overflow-y: scroll;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items:center;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px;
    border: 2px solid rgb(0, 0, 0);
    .news-wrap{
        padding: 30px;
        width: 100%;
        position: relative;
      
        div{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // padding: 10px;
            // margin: 10px;
            // height: 50px;
            position: relative;
            // padding: 5px;
            input{
                // margin: 0;
                width: 70%;
                height: 40px;
            }

          
        }
       

    }
    
}

.auth-post-wrap{

    .auth-posts-form{
        table{
            margin-top: 20px;
            width: 85vw;
            color: black;
            img{
                width: 80px;
            }
        }
        table, tr,th, td {
          border:1px solid black;
          padding: 2px;
        }
        td:nth-child(2){
           width: auto;
        }
        td{
            padding: 0x;
            img{
                width: 60%;
            }
        }
        p{
            color:black;
            font-weight: 600;
            font-size: 1.3em;
        }
    }
}