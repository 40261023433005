.home-wrap{


    .news-select-container{
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 75vh;
        overflow-y: scroll;
        position: relative;

        .news-category{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            position: relative;
            
            div{
                width: 90%;
                height: 5vh;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                overflow-x: scroll;
             

            button{
                position: relative;
                padding: 5px;
                white-space: nowrap;
            overflow: hidden;
            text-overflow: clip;
                
                background: none;
                color: black;
            }
            }
        }

        .news-item-wrap{
            position: relative;
            

            .news-item{
                display: grid;
                grid-template-columns: 2fr 1fr;
                padding: 5px 15px;
                
                .news-item-left{
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: flex-start;
                    h3{
                        // font-size: 0.8rem;
                        font-weight: 500;
                    }
                    h6{
                        color: rgb(77, 77, 77);
                    }

                    .news-item-left-tag{
                        position: relative;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        

                        p{
                            position: relative;
                            color: rgb(227, 44, 12);
                            border: 1px solid rgba(239, 77, 77, 0.762);
                            border-radius: 10px;
                            margin: 3px;
                        }
                    }
                    .news-item-left-footer{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 90%;
                    }
                }

                .news-item-right{
                    img{
                        width: 100%;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 10px;
                        
                    }
                }


            }
        }

           
    }










}

@media screen and (min-width: 450px){
    .home-wrap{
        width: 100%;


        .news-select-container{
            width: 100%;

            .news-grid{
                display: grid;
                grid-template-columns: repeat(3,1fr);
                width: 100%;
                height: 75vh;
                overflow-y: scroll;
        
                .news-item-wrap{
                    hr{
                        display: none;
                    }
        
                    .news-item{
                      
                       
                    }
                }

            }
           
        }
    }
}