

    .shine-container{
        width: 100vw;
        height: 100vh;
        z-index: 100;
        position:absolute;
        top:0;
        @keyframes twinkle {
            0% {
               transform: scale(1);
               opacity: 0.2;
               }
            
            50% {
                transform: scale(2);
                opacity: 1;

            }
            100%{
                transform: scale(1);
               opacity: 0.2;

            }
        }
        
        .shine {
            position: fixed;
            background-color: white;
            height: 3px;
            width: 3px;
            border-radius: 50%;
            animation-name: twinkle;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            top: 50%;
            left: 50%;
        
            }
                 .shine:nth-child(1){
        top: 10%;
        left: 10px;
        animation-delay: 8s;
        z-index: 3;
    }

    }