.auth-news-create{
    position: absolute;
    width: 90vw;
    height: 85vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items:center;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid rgb(0, 0, 0);
    .news-wrap{
        padding: 30px;
        width: 100%;
        position: relative;
        height: 70vh;
        overflow-y: scroll;
        .textarea-news{
            height: 200px;
        }
        div{
            // width: 100%;
            // display: flex;
            justify-content: flex-start;
            align-items: center;
            // padding: 10px;
            // margin: 10px;
            // height: 50px;
            position: relative;
            input{
                padding: 0;
                margin: 0;
                width: 80%;
            }

            textarea{
                height: 200px;
                width: 80%;

            }
        }
       .draft-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 100;
        .wrapper-class {
            padding: 1rem;
            border: 1px solid #ccc;
            width: 100%;
          }
          .editor-class {
            // background-color:lightgray;
            padding: 1rem;
            border: 1px solid #ccc;
          }
          .toolbar-class {
            border: 1px solid #ccc;
            z-index: 100;
          }
       }
       .preview {
        padding: 1rem;
        margin-top: 1rem;
        position: relative;
        p ,h1 ,h2, h3, h4, h5 ,h6{
            color: black;
            position:relative;
            text-align: unset;
        }
      }
    }
    
}