.sms-wrap{

    div{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction:row;
        button{
            margin: 10px;
        }
    }
}


#loading-sms-wrap{
    width: 90vw;
    height: 94vh;
    z-index: 1000;
    top: 3vh;
    left: 5vw;
    position: absolute;
    background-color:#FBFBFB;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.614);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 150px;
    }
    #progress{
        width: 250px;
        height: 250px;
        border: 15px solid rgb(20, 183, 158);
        border-radius: 50%;
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }
}