#switch{
    position: absolute;
    right: 2vw;
    top: 2vh;
    width: 15vw;
    z-index: 2000;
    border: 5px solid white;
    border-radius: 50%;
}

#checkList{
    width: 100vw;
    height: 100vh;
    overflow-y: auto;

    div{
       display: grid;
       grid-template-columns: repeat(2,1fr);
       grid-column-gap: 10vw;
       grid-row-gap: 5vh;
       position: relative;
       h2{
           position: relative;
           top: 50%;

       }
        img{
            width: 100%;
            border: 1px solid black;
            box-shadow: -1px 0px 16px 6px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 0px 16px 6px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 0px 16px 6px rgba(0,0,0,0.75);
        }
    }
  



}