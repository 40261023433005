@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');








.img {

    width: 100% ;
    flex-shrink: 0;
    display: block ;
    object-fit: cover ;

}

.list {

    margin: 0 ;
    padding: 0 ;
    list-style-type: none ;

}

.flex {

    display: flex ;
    align-items: center ;
    justify-content: space-between ;

}

.uppercase{
  
    text-transform: uppercase ;
  
}

.player {

  
    // overflow: hidden ;
    font-size: 1.22em ;
    border-radius: 1.5em ;
 
    // background-color: white ;
    // height: var(--parent-height) ;

}
.player-wrap{
    // display: flex;
    // justify-content: space-between;
    // align-items: center ;
    // padding: 5px;
    // button{
    //    img{ align-items: center ;}
    // }
    padding: 5px;

}

// .player__header {

//     z-index: 1 ;
//     gap: 0 .4em ;
//     width: 100% ;
//     display: flex;
//     height: 4.85em ;
//     flex-shrink: 0 ;
//     position: relative;
//     align-items: flex-start ;
//     border-radius: inherit ;
//     justify-content: flex-end ;
//     background-color: white  ;
//     padding: .15em 0.2em 0 1.2em ;
//     box-shadow: 0 2px 6px 1px #0000001f ;
//     transition: height var(--cubic-header), box-shadow var(--duration), padding var(--duration) ease-in-out ;

// }

.player__header.open-header {

    height: 100% ;
    padding-left: 0 ;
    padding-right: 0 ;
    box-shadow: unset ;

}
.player__img--absolute {
  
    position:relative ;
    
}
.player__img {

    width: 3.22em ;
    height: 3.22em ;
    border-radius: 1.32em ;

}



.slider {

    flex-shrink: 0 ;
    overflow: hidden ;
    transition: width var(--cubic-header), height var(--cubic-header), top var(--cubic-header), left var(--cubic-header);
    
}

.slider.open-slider{

    top: 0 ;
    left: 0 ;
    width: 100% ;
    height: 14.6em ;

}

.slider__content {

    display: flex ;
    height: 100% ;
    will-change : transform ;
    transition: transform var(--cubic-slider);

}

.slider__img {

    filter: brightness(75%) ;
    border-radius: 50%;
    
}

.slider__img__rotation{
    filter: brightness(75%) ;
    border-radius: 50%;
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
 
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.slider__name, 
.slider__title {
  
    overflow: hidden ;
    white-space: nowrap ;

}

.text-wrap {

    display: block ;
    white-space: pre ;
    width: fit-content ;
    animation: text-wrap var(--duration-text-wrap) infinite ;

}

@keyframes text-wrap {

    75%{
      
        transform: translate3d(-51.5%, 0, 0) ;
      
    }

    100%{
      
        transform: translate3d(-51.5%, 0, 0) ;

    }
    
}

.player__button {

    all: unset ;
    z-index: 100 ;
    width: 2.5em ;
    height: 2.5em ;
    cursor: pointer ;

}

.playlist {

    transform: scale(0) ;
    transition: transform calc(var(--duration) / 2) ;
    
}

.slider.open-slider .playlist {

    transform: scale(1) ;
    transition: transform var(--cubic-play-list) ;


}

.player__button--absolute--nw {

    top: 5.5% ;
    left: 5.5% ;
    position: absolute ;
    
}

.player__button--absolute--center {
    
    top: 0 ;
    left: 0 ;
    right: 0 ;
    bottom: 0 ;
    margin: auto ;
    position: absolute ;

}

img[alt ="pause-icon"] {

    display: none ;
    
}


.player__controls {

    width: 77% ;
    gap: .5em 0 ;
    display: flex ;
    flex-wrap: wrap ;
    align-items: center ;
    will-change: contents ;
    align-content: center ;
    justify-content: center ;
    transition: transform var(--cubic-header) , width var(--cubic-header) ;

}

.player__controls.move {

    width: 88% ;
    transform: translate3d(-1.1em , calc(var(--parent-height) - 153%) , 0) ;
    
}

.player__context {

    margin: 0 ;
    width: 100% ;
    display: flex ;
    line-height: 1.8 ;
    flex-direction: column ;
    justify-content: center ;
    text-transform: capitalize ;

}

.slider__context {

    width: 56.28% ;
    cursor: pointer ;
    text-align: center ;
    padding-bottom: .2em ;
    will-change: contents ;
    transition: width var(--cubic-header) ;
    animation: calc(var(--duration) / 2) var(--cubic-slider-context) ;

}

@keyframes opacity {
   

    0% {

        opacity: 0 ;

    }

    90%{

        opacity: 1 ;

    }

}

.player__controls.move .slider__context{
    
    width: 49.48% ;

}



.progres {

    width: 90% ;
    height: .25em ;
    cursor: pointer ;
    border-radius: 1em ;
    touch-action : none ;
    background-color: #e5e7ea ;
    transition: width var(--cubic-header) ;

}

.player__controls.move .progres{

    width: 98% ;
    
}

.progres__filled {

    width: 0% ;
    height: 100% ;
    display: flex ;
    position: relative ;
    align-items: center ;
    border-radius: inherit ;
    background-color: #78adfe ;
    
}

.progres__filled::before {

    right: 0 ;
    width: .35em ;
    content: " " ;
    height: .35em ;
    border-radius: 50% ;
    position: absolute ;
    background-color: #5781bd ;
    
}

.player__playlist {

    height: 100% ;
    overflow: auto ;   
    padding: 1.05em .9em 0 1.2em ; 

}

.player__playlist::-webkit-scrollbar {
    
    width: 0 ;

}

.player__song {

/*     gap: 0 .65em ; */
    display: flex ;
    cursor: pointer ;
    margin-bottom: .5em ;
    padding-bottom: .7em ;
    border-bottom: .1em solid #d8d8d859 ;

}

.player__song .player__context {

    line-height: 1.5 ;
    margin-left: .65em ;

}





.audio {

    display: none !important ;

}

#player-title{
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 50%;
    p{
        color: black;
        position:relative;
    }
}

.player__song-name{
    color: black;
    position:relative;

}
.player__title{
    color: black;
    position:relative;

}
.des-player{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 5px;
}