@import url(https://weloveiconfonts.com/api/?family=brandico);
@import url(https://fonts.googleapis.com/css?family=Junge);

.org-container{
    width: 100%;
    height: 100%;
    background: #fbfbfb;

    #org-play{
        position:absolute;
        right: 5vw;
        top: 1vh;
        z-index: 20000;
        
        svg{
            width:50px;
            height: 50px;
            z-index: 20000;
            color: white;
        }
    }
    #org-list{
        position:absolute;
        left: 5vw;
        top: 1vh;
        z-index: 20000;
        
        svg{
            width:50px;
            height: 50px;
            z-index: 20000;
            color: white;
        }
    }

    .org-slider{
        position:absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -140px;
        top: 30vh;
        z-index: 20000;
       
        transform: rotate(-90deg);
        svg{
            transform: rotate(90deg);
            width:50px;
            height: 50px;
            z-index: 20000;
            color: white;
        }
        input[type="range"] {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            width: 300px;
            height: 20px;
            margin: 0;
            border: none;
            padding: 1px 2px;
            border-radius: 14px;
            background: #ffffff;
            box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
            -webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
            outline: none; /* no focus outline */
          }
          
          input[type="range"]::-moz-range-track {
            border: inherit;
            background: transparent;
          }
          
          input[type="range"]::-ms-track {
            border: inherit;
            color: transparent; /* don't drawn vertical reference line */
            background: transparent;
          }
          
          input[type="range"]::-ms-fill-lower,
          input[type="range"]::-ms-fill-upper {
            background: transparent;
          }
          
          input[type="range"]::-ms-tooltip {
            display: none;
          }
          
          /* thumb */
          
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 40px;
            height: 18px;
            border: none;
            border-radius: 12px;
            background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #529de1), color-stop(100%, #245e8f)); /* android <= 2.2 */
            background-image: -webkit-linear-gradient(top , #529de1 0, #245e8f 100%); /* older mobile safari and android > 2.2 */;
            background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%); /* W3C */
          }
          input[type="range"]::-moz-range-thumb {
            width: 40px;
            height: 18px;
            border: none;
            border-radius: 12px;
            background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%); /* W3C */
          }
          
          input[type="range"]::-ms-thumb {
            width: 40px;
            height: 18px;
            border-radius: 12px;
            border: 0;
            background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%); /* W3C */
          }
    }
    #canvas-bg{
        position: absolute;
        height: 100vh;
        width: 100vw;
        canvas{
            position: absolute;
            height: 100vh;
            width: 100vw;
        }
       
    }

    .org-pop{
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(239, 249, 249);
        z-index: 20000;
        background:url('https://res.cloudinary.com/dvr397xda/image/upload/v1675964936/logo/pexels-luis-quintero-1753260_lxogov.jpg')no-repeat;
        background-size:cover;
        font-family:junge,georgia;
        text-align:center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg{
            position: absolute;
            right: 20px;
            top: 20px;
            scale: 2.5;
            z-index: 21000;
        }
        .org-pop-wrap{
            width: 90vw;
            height:95vh;
            background-color: #ffffff;
            border-radius: 20px;
            overflow-y: scroll;
            display: flex;
        align-items: center;
        flex-direction: column;
           .org-pop-wrap-top{
            background-image: url(https://res.cloudinary.com/dvr397xda/image/upload/v1675967600/logo/pexels-david-mcbee-396303_vz2bfq.jpg);
            width: 100%;
            // background-size:cover;

            img{
                width: 150px;
            }
            p{
                position: relative;
                padding: 5px;
            }
           }
        
           .org-pop-wrap-team{
            position: relative;
                        // overflow-y: scroll;

            width: 100%;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            div{
                img{
                    width: 170px;
                }
            }
        }
            
            
            
            
           
           
            
           
            
              
        }
      
      
    }
}