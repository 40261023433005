


.user-wrap{
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.774);
    display: flex;
    align-items: center;
    flex-direction: column;
 

    .recommand-content{
        width: 96vw;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 5px;
        padding: 5px;
        h3{
            padding: 0;
            margin: 0;
        }
        .user-like-check{
            width: 95%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                width: 30%;
                padding: 5px;
            }
            margin-bottom: 10px;
        }



        .recommand-card{
            width: 100%;
            height: 83vh;
            overflow-y: scroll;
            background-color: #212534;
            .cards-wrap{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                width: 100%;
                .cards-item{
                    margin-top: 15px;
                    padding: 5px;
                    margin: 5px;
                    background-color: rgba(255, 255, 255, 0.925);
                    width: 90%;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-radius: 6px;
                    box-shadow: 0 8px 16px -8px rgba(252, 252, 252, 0.4);
                    
                    .cards-item-left{
                        display: flex;
                    justify-content: flex-start;
                    align-items: center;
                        flex-direction:column;
                    }
                    .cards-item-right{
                        img{
                            width: 100%;
                        }
                    }
                }

              


            }
            .cards-wrap2{
                display:flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                .pagenation-wrap{
                    p{
                        color: white;
                    }
                }
                .cards-item{
                    position: relative;
                    margin-top: 15px;
                    padding: 5px;
                    margin: 5px;
                    background-color: rgba(255, 255, 255, 0);
                    width: 90%;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-radius: 6px;
                    // box-shadow: 0 8px 16px -8px rgba(252, 252, 252, 0.4);
                    
                    .cards-item-left{
                        display: flex;
                    justify-content: flex-start;
                    align-items: center;
                        flex-direction:column;
                        color: white;
                    }
                    .cards-item-right{
                        position: relative;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        img{
                            width: 100%;
                            backface-visibility: hidden;
                            overflow: hidden;
                        }
                        :nth-child(2){
                            position: absolute
                        }
                    }
                }

              


            }
            .cards-wrap3{
                display:flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                .pagenation-wrap{
                    p{
                        color: white;
                    }
                }
                .cards-item{
                    position: relative;
                    margin-top: 15px;
                    padding: 5px;
                    margin: 5px;
                    background-color: rgba(255, 255, 255, 0);
                    width: 90%;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-radius: 6px;
                    // box-shadow: 0 8px 16px -8px rgba(252, 252, 252, 0.4);
                    
                    .cards-item-left{
                        display: flex;
                    justify-content: flex-start;
                    align-items: center;
                        flex-direction:column;
                        color: white;
                    }
                    .cards-item-right{
                        position: relative;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        img{
                            width: 100%;
                            backface-visibility: hidden;
                            overflow: hidden;
                        }
                        :nth-child(2){
                            position: absolute
                        }
                    }
                }

              


            }
            .posts-wrap{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                width: 100%;
                .cards-item{
                    margin-top: 15px;
                    padding: 5px;
                    margin: 5px;
                    background-color: rgba(10, 11, 66, 0.925);
                    width: 90%;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-radius: 6px;
                    box-shadow: 0 8px 16px -8px rgba(252, 252, 252, 0.4);
                    color: white;
                    .cards-item-left{
                        display: flex;
                    justify-content: flex-start;
                    align-items: center;
                        flex-direction:column;
                    }
                    .cards-item-right{
                        img{
                            width: 100%;
                        }
                    }
                }
            }

        }


    }

}



@media only screen and (min-width: 768px){

    .recommand-content{
        width: 96vw;
        height: 70vh;
        overflow-y: scroll;
        padding-bottom: 50px;
    }
}


@media screen and (max-width: 375px){
    .user-wrap{
    .recommand-content{
        width: 96vw;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 5px;
        padding: 5px;
      

        .recommand-card{
            width: 100%;
            height: 80vh;
            overflow-y: scroll;
        }

    }
}
}