.card-container{
    height: 100%;
    /* for touch screen */
    touch-action: none; 
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #111;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            z-index: 100;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
}


#drag-container, #spin-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: rotateX(-10deg);
            transform: rotateX(-10deg);
            #gif{
              position: absolute;
              transform-style: preserve-3d;
            
              // transform:  rotateX(90deg);
              width: 100%;
              height: auto;
            }
  }
  
  #drag-container  img, #drag-container video {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 200px;
    font-size: 50px;
    text-align: center;
    -webkit-box-shadow: 0 0 8px #fff;
            box-shadow: 0 0 8px #fff;
    -webkit-box-reflect: below 10px linear-gradient(transparent, transparent, #0005);
  }
  
  
  #drag-container img:hover, #drag-container video:hover {
    -webkit-box-shadow: 0 0 15px #fffd;
            box-shadow: 0 0 15px #fffd;
    -webkit-box-reflect: below 10px linear-gradient(transparent, transparent, #0007);
  }
  
  div div  p{
      /* -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; */
    position: absolute;
      color: white;
    font-size: 10px;
    width:100%;
   text-align: center;
  }
  
  #company{
    position: absolute;
    font-size: 5rem;
    transform: translate(-50%,-50%) scale(1.1) ;
    // transition: 3s;
    // animation: spinRevert infinite;

  }
  #warp-p {
    font-family: Serif;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%) rotateX(90deg);
            transform: translate(-50%,-50%) rotateX(90deg);
    color: #fff;
    font-size: xx-large;
    font-weight: bolder;
   
  }
  
  #ground {
    width: 900px;
    height: 900px;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%) rotateX(90deg);
            transform: translate(-50%,-50%) rotateX(90deg);
    background: -webkit-radial-gradient(center center, farthest-side , rgba(245, 245, 245, 0.2), transparent);
    // background-image: url('https://res.cloudinary.com/dlapk94rx/image/upload/v1644614268/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/q_q_nlwuh1.png') ;
    background-repeat: no-repeat;
    opacity: 0.2;
    background-size: cover;
  }
  
  #music-container {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  a{
      display: contents;
  }
  
  @-webkit-keyframes spin {
    from{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    } to{
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
  }
  
  @keyframes spin {
    from{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    } to{
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }
  }
  @-webkit-keyframes spinRevert {
    from{
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    } to{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    }
  }
  @keyframes spinRevert {
    from{
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    } to{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    }
  }

  .disabled-link {
    pointer-events: none;
  }
  .ob{
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translate(-50%,-50%) ;   
    //   z-index: 10000;
      color:white;
      #company-chinese{

        font-size: 2rem;
        font-weight: bold;
      }
     

  }
  #company-des{
    position:absolute;
    top: 15%;
    font-size: larger;
}
#company-title{
    position:absolute;
    top: 10%;
    font-size: larger;
}
#light{
    position: absolute;
    left: 0%;
    opacity: 0.4;
    z-index: -1;
    width: 100%;
}