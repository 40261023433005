.nav-container{
    height: 40px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(211, 87, 49);
    // position: absolute;
    // top: 0;
    .nav-left{
        width: 55%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-left: 20px;

        img{
            width: 40px;
        }
    }
    .nav-right{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg{
            scale: 1.8;
            color: white;
        }
        padding-right: 20px;
        padding-top: 5px;
        a{
            font-size: small;
            color: white;

        }
    }
}

@media screen and (min-width: 750px) {

    .nav-container{
        height: 60px;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
           background-color: rgb(211, 87, 49);
        ;
        .nav-left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            // padding-left: 100px;
    
            img{
                width: 80px;
            }
        }
        .nav-right{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img{
                width: 60px;
            }
            // padding-right: 100px;
        }
    }

}