
 
.home-wrap{
    width: 100vw;
    height: 100vh;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F2F2F2;



.home-container-mid{
    width: 96vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    
}

.home-container-mid-right{
    text-align: right;
}
.slide-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider {
    background: white;
    height: 110px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 350px;
}

.slider::before, .slider::after {
    // background: linear-gradient(to right, rgba(255, 255, 255, 0.188) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100%;
    position: absolute;
    width: 100px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider .slide-track {
    animation: scrollt 50s linear infinite;
    display: flex;
    width: calc(100px * 16);
    
}

.slider .slide-track:hover {
    animation-play-state: paused;
}

.slider .slide {
    height: 100px;
    width: 100px;
}
.slide{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    p{
        color: black;
        position: relative;
    }
}

    .home-container{
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 75vh;
        overflow-y: scroll;

        .home-scroll{



        }

        .search{
            width: 80vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            // padding: 5px 0px;
            margin: 5px 0px;
            input{
                margin: 0;
            }
            svg{
                scale:1.5;
                margin: 10px;
            }
            div{

                padding: 0 5px;
                margin: 1px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button{
                    color: black;
                    white-space: nowrap;
                    font-size: small;
                    display: flex;
                    // justify-content: space-between;
                    align-items: center;
                    padding: 5px;
                }
                svg{
                    background-color: red;
                    border: 50%;
                    scale: 1.5;

                }
            }
          
        }
    }


    .home-city-wrap{
        width: 96vw;
        overflow-x: scroll;

        div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: fit-content;

            button{
                color: black;
            white-space: nowrap;
            font-size: small;
            background-color: rgba(255, 255, 255, 0);
            padding: 5px;
            margin: 3px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow:none;
            border-radius: none;
            svg{
                background-color: red;
                border: 50%;
            }
            }
        }

    }

    .home-category-wrap{
        width: 96vw;
        overflow-x: scroll;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: fit-content;
            button{
                color: black;
            white-space: nowrap;
            font-size: small;
            padding: 5px;
            margin: 3px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: rgba(255, 255, 255, 0);
            box-shadow:none;
        }

        

    }
    }
    .home-post-wrap{
        width: 96vw;
        // height: 50vh;
        // overflow-y: scroll;

        .home-post-container{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;
            .home-post-item{
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                margin-top: 5px;
                background-color: white;
                padding: 10px;
                border-radius: 10px;
                .home-post-top2{
                    svg{
                        color: rgb(255, 16, 16);
                      }
                    h3{
                        white-space: normal;
                        color: rgb(4, 136, 153);
                        font-weight: 700;
                    }
    
                    p{
                        position: relative;
                        color:black;
                        font-size: medium;
                        text-align: left;
                    }
                }
                .home-post-top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 30px;
                    }
                    p{
                        position: relative;
                        color:black;
                        font-size: medium;
                        text-align: right;
                    }
                    div{
                        padding-left: 10px;
                        svg{
                            scale: 1.2;

                        }
                    }
                    svg{
                      color: rgb(255, 16, 16);
                    }
                   
                }
                .home-post-mid{
                    padding-top: 5px;
                    div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 5px;
                        :nth-child(2){
                            text-align: right;
                            a{
                                

                                // background-image: linear-gradient(
                                //   -225deg,
                                //   #000000 0%,
                                //   #ffffff 29%,
                                //   #000000 67%,
                                //   #000000 100%
                                // );
                                background-size: auto auto;
                                background-clip: border-box;
                                background-size: 200% auto;
                                color: rgb(223, 43, 19);
                                background-clip: text;
                                // text-fill-color: transparent;
                                // -webkit-background-clip: text;
                                // -webkit-text-fill-color: transparent;
                                animation: star 4s linear infinite;
                                display: inline-block;
                                    font-size: 19px;
                                    font-weight: 500;
                                
                                // animation: animateText 3s linear infinite;
                              
                             
                            }
                        }
                      
                    }

                    .home-post-mid-img{
                        width: 100%;
                        height: 120px;
                        overflow-x: scroll;
                        div{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            width: fit-content;
                        }
                        img{
                            height: 120px;
                            width: auto;
                            border-radius: 5px;
                            padding-left: 5px;
                        }
                    }

                }
                .home-post-bottom{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-top: 5px;
                    .home-post-bottom-left{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        
                        p{
                            white-space: nowrap;
                            color: rgb(121, 121, 121);
                            font-size: small;

                        }
                    }
                    .home-post-bottom-right{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        svg{
                            padding-right: 2px;
                        }
                        p{
                            white-space: nowrap;
                            color: rgb(121, 121, 121);
                            font-size: small;
                        }
                    }
                }
                h3{
                    white-space: nowrap;
                    color: rgb(4, 136, 153);
                    font-weight: 700;
                }

                p{
                    position: relative;
                    color:black;
                    font-size: medium;
                    text-align: left;
                }
            }

            
        }


    }

    .pagenation-wrap{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
        width: 98vw;
        height: 3vh;
        margin-top: 5px;
        
        div{
            display: flex;
            justify-content:center;
            align-items:center;
            p{
                color: black;
            }
        }
    }

}




@media screen and (min-width:375px){
    .home-wrap{
       
        .home-container{
            height: 50vh;
        }
    }
   
    
}
@media screen and (min-width:390px){
    .home-wrap{
       
        .home-container{
            height: 54vh;
        }
    }
    
}

@media screen and (min-width:414px){
    .home-wrap{
       
        .home-container{
            height: 54vh;
        }
    }
    
}
@media screen and (min-width:650px){
    .home-wrap{
        .slider {
            background: white;
            height: 110px;
            margin: auto;
            overflow: hidden;
            position: relative;
            width: 500px;
        }
    }
}

@media screen and (min-width:950px){
    .home-wrap{
        .slider {
            background: white;
            height: 110px;
            margin: auto;
            overflow: hidden;
            position: relative;
            width: 700px;
        }
    }
}

@media screen and (min-width:1300px){
    .home-wrap{
        .slider {
            background: white;
            height: 110px;
            margin: auto;
            overflow: hidden;
            position: relative;
            width: 1100px;
        }
    }
}


@keyframes animateText {
    0% {
        color: black;
    }
    50%{
        color: rgb(99, 99, 99);

    }
    100% {
        color: black;

    }
  }

  @keyframes shine {
    0% {
      background-position-x: -500%;
    }
    100% {
      background-position-x: 500%;
    }
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  @keyframes star {
    0%{
        color: rgb(223, 43, 19);

    }
    50%{
        color: rgba(223, 43, 19, 0.486);

    }
    100%{
        color: rgb(223, 43, 19);

    }
  }

  @keyframes scrollt {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100px * 15));
    }
}