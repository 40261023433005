
.container-card{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.example-title{
    position: absolute;
    top: 1vh;
    width: 60vw;
    left: 20vw;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    font-size:1.6em;
}
.select-wrap{
    position: absolute;
    width: 90vw;
    background-color: #fff;
    top: 6vh;
    left:5vw;
    z-index: 150000;
    border-radius: 5px;
    div{
        width:100%;
        z-index: 150000;
        overflow-x: scroll;
        table, tr,th, td {
            border:1px solid black;
          
            padding: 5px;
            z-index: 150000;
            input{
                width: 7vw;
                height: 7vw;
                
                margin:0;
            }
          }
    }
  
    
}

.wrapper{
    
   

    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 50vh;
   
    background-color: rgba(61, 65, 65, 0.342);
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        z-index: 1000;
        h3{
            color: #fff;
        }
        #qr{
            width: 80%;
        }
        #close{
            width: 40px;
            position: absolute;
            right: 10px;
            z-index:2000;
        }
    #page-item{

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 5vh;
        height: 40vh !important;

    }
    .demoPage{

        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            width: 150px;
        }
    }
}



.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width:100vw;
        height:auto;
    }
}


@media screen and(min-width:750px) {
    .container-card{


        .wrapper{
            width: 60vw;
            height: 50vh;
            left: 20vw;
            position: fixed;
            #qr{
                width: 40%;
            }
        }
    }

    .select-wrap{
    position: absolute;
    width: 50vw;
    background-color: #fff;
    top: 6vh;
    left:25vw;
    z-index: 150000;
    border-radius: 5px;
    box-shadow: 2px 4px 7px 6px rgba(0,0,0,0.4);
-webkit-box-shadow: 2px 4px 7px 6px rgba(0,0,0,0.4);
-moz-box-shadow: 2px 4px 7px 6px rgba(0,0,0,0.4);
    div{
        width:100%;
        overflow-x: scroll;
        z-index: 150000;
        table, tr,th, td {
            border:1px solid black;
          
            padding: 2px;
            input{
                width: 3vw;
                height: 3vw;
                
                margin:0;
            }
          }
    }
  
    
}
   
 
}

#cd-player{
    position:fixed;
    bottom: 2vh;
    right: 5vw;
    z-index: 2500;
}

#meta-btn{
    position:fixed;
    bottom: 2vh;
    left: 5vw;
    z-index: 2500;

    img{
        width: 60px;
        box-shadow: 2px 1px 34px 3px rgba(61,61,61,0.75);
        -webkit-box-shadow: 2px 1px 34px 3px rgba(61,61,61,0.75);
        -moz-box-shadow: 2px 1px 34px 3px rgba(61,61,61,0.75);
        border-radius: 50%;
    }
}