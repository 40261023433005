
.container-card{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
    .canvas-wrap{
        position: absolute;
        height: 100vh;
        width: 100vw;
        canvas{
            position: absolute;
            height: 100vh;
            width: 100vw;
        }
       
    }
}
#return-home{
  
    z-index: 2500;

    img{
        width: 60px;
        border-radius: 50%;
        box-shadow: 0 0 2px 0 #555;
        background-color: rgb(0, 0, 0);
        // position: absolute;
        position: fixed;
        top: 1vh;
        left: 2vw;
        z-index: 2500;
    }
}
#share-btn{
    z-index: 2500;
    position: absolute;
    top: 2vh;
    right: 5vw;
    scale: 2;

   
}
.card-like-wrap{
    position: fixed;
    bottom: 16vh;
    right: 50px;
    z-index: 100000;

    svg{
       scale:3;
       box-shadow: -3px 3px 16px -200px rgba(181,181,181,0.78);
       -webkit-box-shadow: -3px 3px 16px -200px rgba(181,181,181,0.78);
       -moz-box-shadow: -3px 3px 16px -200px rgba(181,181,181,0.78);
       z-index: 100000;
    }
}
.wrapper{
    position: fixed;
    // top: 200%;
    width: 100vw;
    height: 50vh;
   
    background-color: rgba(61, 65, 65, 0.342);
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;
        // z-index: 1000;
        h3{
            color: #fff;
        }
        #qr{
            width: 80%;
        }
        #close{
            width: 40px;
            position: absolute;
            right: 10px;
        }
    #page-item{

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 5vh;
        height: 40vh !important;
    }
    .demoPage{

        background-color: rgb(221, 202, 183);
        color: rgb(0, 0, 0);
        border: solid 1px hsl(35, 20, 70);
        overflow: hidden; 
        // z-index: 100;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: center;

        h2{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center;
            font-size: 18px;
        }
        h3{
            position: relative;
            margin-top: 5%;
            left: 10%;
            width: 80%;
            text-align: center; 
        }
       
        img{ 
            position: relative;
            margin-top: 20px;
            left: 10%;
            width: 80%;
            width: 150px;
        }
    }
}

.load-image{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(49, 49, 49, 0.842);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width:100vw;
        height:auto;
    }
}

@media screen and(min-width:750px) {
    .container-card{
        .wrapper{
            width: 60vw;
            height: 50vh;
            left: 20vw;
            position: fixed;
            #qr{
                width: 40%;
            }
        }
    }
}
#cd-player{
    position:fixed;
    top: 10vh;
    left: 1vw;
    z-index: 2500;
}
.view-wrap{
    position:fixed;
    bottom: 15vh;
    left: 5vw;
    z-index: 2500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #020202b4;
    padding: 6px;
    border-radius: 10px;
    img{
        width:25px;
        border-radius: 50%;
    }
    p{
        position: relative;
        top:0;
        color:white;
        font-weight: 700;
        font-size:0.8rem;
        padding-left: 5px;       
    }
}

#miniMap{
    position: fixed;
    right: 10vw;
    top:1vh;
    width: 20vw;
    z-index:2000;
    img{
        width:100%;
    }
}
$easing: cubic-bezier(.33,.33,.33,1);
$background:  darken(#daecf3, 12);
$gradient_end: darken(#D7EDEC, 8);
.click-container{
    img{
        width: 12px;
        background: rgba(255, 255, 255, 0);
        border-radius: 50%;
        border: none;
        padding: 0;
        position: relative;
        animation: shockwave 1s .5s ease-out infinite; 
    }
}
  @keyframes shockwave {
    0% {
      transform: scale(1);
      box-shadow: 0 0 2px rgba(0,0,0,0.15), inset 0 0 1px rgba(0,0,0,0.15);
    }
    95% {
      box-shadow: 0 0 50px rgba(0,0,0,0), inset 0 0 30px rgba(0,0,0,0);
    }
    100% {
      transform: scale(1.25);
    }
  }
