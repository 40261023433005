.home-wrap{


    .coupon-select-container{
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 75vh;
        overflow-y: scroll;

       

        .coupon-item-wrap{
           width: 92%;
           display: grid;
           grid-template-columns: 1fr 2fr;
           grid-column-gap: 20px;
            margin-top: 10px;
            hr{
                width: 100%;
                color: rgba(169, 169, 169, 0.481);
                position: absolute;
                bottom: 0px;
                margin-top: 5px;
            }
            .coupon-item-img{
                width: 100%;

                img{
                    width: 130px;
                    height: 130px;
                    object-fit: fill;
                    border-radius: 10px;
                }

            }

            .coupon-item-content{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                padding: 10px 0px;
              
                h2{
                    font-size: 1rem;
                    font-weight: 400;
                }
                div{
                    width: 100%;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    p{
                        position: relative;
                        color: black;
                        text-align: start;
                        svg{
                            scale: 2;
                        }
                        span{
                            padding-left: 10px;
                         
                        }
                    }
                    p:last-child{
                        text-align: end;
                    }
                }
                h4{
                    font-weight: 400;
                    span{
                        text-decoration: line-through;
                    }
                }
                h3{
                    font-weight: 700;
                    color: rgb(255, 0, 0);

                    span{
                    }
                }
                h6{
                    font-size: 0.8rem;
                    span{
                        border: 1px solid black;
                        margin: 0 2px;
                        padding: 2px 5px;
                        background-color: rgb(187, 187, 187);
                    }
                }

            }

        }
       

           
    }










}

@media screen and (min-width: 450px){
    .home-wrap{


        .coupon-select-container{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            width: 100%;
            height: 75vh;
            overflow-y: scroll;
    
           
        }
    }
}