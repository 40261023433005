.file-container{
    background-color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    input{
        margin: 0;
    }
}

