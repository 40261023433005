.star-box{
    width: 100%;
    height: 100%;
    position: relative;
    transform:rotateZ(150deg);
    z-index: 0;
    @keyframes meteors{
        0%{
            width: 0;
        }
        50%{
            width: 230px;
        }
       
        100%{
            width: 0px;
        }
    }
    @keyframes move{
        0%{
            transform: translateX(0)
        }
        100%{
            transform: translateX(1800px)
        }
    }

    .star{
        height: 3px;
        position: absolute;
        // border-radius: 30%;
        border-top-left-radius: 80%;
        border-top-right-radius: 30%;
        border-bottom-left-radius: 80%;
        border-bottom-right-radius: 30%;
        filter: drop-shadow(0 0 7px #ebf7f8);
        background: linear-gradient(-45deg,#c7ecee,rgba(0,0,255,0));
        animation: meteors 6s ease-in-out infinite , move 6s ease-in-out infinite;
        top: 70%;
        left: 300px;
    }

    .star:nth-child(1){
        top: 20%;
        left: 330px;
        animation-delay: 7s;
    }

 
    // .star:nth-child(3){
    //     top: 80%;
    //     left: 10px;
    //     animation-delay: 8s;
    //     z-index: 3;
    // }
    // .star:nth-child(4){
    //     top: 70%;
    //     left: 10px;
    //     animation-delay: 3s;
    //     z-index: 3;
    // }
    // .star:nth-child(5){
    //     top: 60%;
    //     left: 1px;
    //     animation-delay: 5s;
    //     z-index: 3;
    // }
    // .star:nth-child(6){
    //     top: 30%;
    //     left: 1px;
    //     animation-delay: 1s;
    //     z-index: 3;
    // }
    // .star:nth-child(7){
    //     top: 75%;
    //     left: -100px;
    //     animation-delay: 5s;
    //     z-index: 3;
    // }
    // .star:nth-child(8){
    //     top: 45%;
    //     right: 20%;
    //     animation-delay: 5s;
    //     z-index: 3;
    // }
    // .star:nth-child(9){
    //     top: 80%;
    //     left: 20%;
    //     animation-delay: 5s;
    //     z-index: 3;
    // }

}