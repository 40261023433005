.auth-post-wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: white;

    .auth-post-btns{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        button{
            width: 20%;
        }
    }

    .auth-posts-form{
        width: 95vw;
        height: 80vh;
        overflow-y: scroll;
        table{
            margin-top: 20px;
            width: 95vw;
          
        
            color: black;
            img{
                width: 80px;
            }
        }
        table, tr,th, td {
          border:1px solid black;
        
          padding: 10px;
        }
        td:nth-child(2){
           width: 30vw;
        }
        p{
            color:black;
            font-weight: 600;
            font-size: 1.3em;
        }
        .post-delete-group{
          
            button:nth-child(2){
                background-color: brown;
            }
            button:nth-child(1){
                background-color: rgb(13, 218, 64);
            }
        }
    }

    .pagenation-wrap{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
        width: 98vw;
        height: 3vh;
        margin-top: 5px;
        
        div{
            display: flex;
            justify-content:center;
            align-items:center;
            p{
                color: black;
                font-size: large;
            }
        }
    }

    .auth-post-update{
        position: absolute;
        width: 90vw;
        height: 90vh;
        background-color: rgba(41, 41, 41, 0.658);
        display: flex;
        align-items:center;
        flex-direction: column;
        border-radius: 10px;
        padding: 10px;
        .sub-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            margin-top: 200px;

            button{
                width: 20%;
            }
        }
        h4{
            color:white;
            position: relative;
        }
        p{
            color:white;
            position: relative;
            font-size: large;
            height: 30vh;
            width: 100%;
            overflow-y: scroll;
        }
        label{
            color:white;
            font-size: larger;
            margin-top: 10px;
        }
    }

}