@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.container-card{
    background: rgb(8,180,172);
    
    background: radial-gradient(circle, rgba(8,180,172,1) 0%, rgba(218,179,236,1) 29%, rgba(0, 213, 255, 0.514) 100%);
  
    #canvas-wrap {
        touch-action: none;
        filter: none;
      }
}



.annotation {
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 8px;
  font-weight: 300;
  background: black;
  color: #f0f0f0;
  padding: 2px 10px;
  border-radius: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
