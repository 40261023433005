.tv-wrap{
    width: 100vw;
    height: 50vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 50%;
    }
    button{
        width: 30%;
    }
}

.user-wrap{
    width: 100vw;
    height: 100vh;
    background-color: rgb(254, 254, 254);
    position: relative;
    hr{
        width: 100%;
        color: black;
    }
    .user-content3{
        position: relative;
        background-color: rgb(255, 255, 255);
        width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
        .tv-bot{
            width: 90vw;
            .game-video-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 96%;
                button{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 80%;
                    margin: 10px 5px;
    
                    svg{
                        scale: 1.5;
                        color: rgb(248, 248, 248);
                    }
    
                }
            }
            
        }
    }
}
