.canvas-wrap{
    width: 100vw;
    height: 100vh;
    canvas{
        width: 100vw;
        height: 100vh;
    }


 
}


#pop-left{
    z-index: 10;
    background-color: rgba(70, 133, 133, 0.418);
    width: 90vw;
    height: 60vh;
    position: fixed;
    left: 5%;
    top: 20%;
    display: grid;
    padding: 5%;
    border-radius: 30px;
    transition: 1.5s;
}
#close{
    width: 35px;
    position: absolute;
    right: 0;
    top: 0;
}

#pop-right{
    z-index: 10;
    background-color: rgba(70, 133, 133, 0.418);
    width: 90vw;
    height: 60vh;
    position: fixed;
    left: 5%;
    top: 20%;
    display: grid;
    padding: 5%;
    border-radius: 30px;
    transition: 1.5s;
}

#pop-back{
    z-index: 10;
    background-color: rgba(70, 133, 133, 0.418);
    width: 90vw;
    height: 60vh;
    position: fixed;
    left: 5%;
    top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    transition: 1.5s;
    iframe{
        width: 100%;
    }
}