.container{
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 100;
    position:absolute;
    // background-image: url(https://www.bhmpics.com/download/space_universe_galaxy-3840x2160.jpg);
    opacity: 1;
    background-size: 100%;
    background-position: center;
    background-size: cover;

    @keyframes title{
        0%{
            transform: scale(1) ;
            opacity: 0.8;

        }
        50%{
            transform: scale(1.3) ;
            opacity: 1;

        }
        100%{
            transform: scale(1) ;
            opacity: 0.8;

        }
    }

    @keyframes bar-left {
        0% {
          transform: translateX(-1%) scale(1.1);
        }
        50% {
          transform: translateX(0) scale(1);
        }
        100% {
            transform: translateX(-1%) scale(1.1);
          }
      }
      @keyframes bar-right {
        0% {
          transform: translateX(1%) scale(1.1);
        }
        50% {
          transform: translateX(0) scale(1);
        }
        100% {
            transform: translateX(1%) scale(1.1);
          }
      }

      @keyframes bot-nav {
        0% {
          transform: translateY(20%) scale(1);
          opacity: 0.8;
        }
        50% {
          transform: translateY(0) scale(1.1);
          opacity: 1;
        }
        100% {
            transform: translateY(20%) scale(1);
            opacity: 0.8;
          }
      }
   
    .test{
        color: rgb(218, 88, 88);
        position: absolute;
        top: 20%;
        left: 30%;
        font-size: 60px;
        z-index:1;
    }
    .star-container{
        position: absolute;
    }
    .starFront{
        position: absolute;

    }
    .logo{
        position:absolute;
        left: 35vw;
        bottom: 0;
        img{
            animation-name: title;
            animation-duration: 15s;
            animation-iteration-count: infinite;
            width: 30vw;
            height: auto;
           
            z-index: 1000;
            // filter: blur(5px);
        }
     
      
    }
    .bot-bar{

        img{
            position:absolute;
            bottom: 2%;
            left: 20%;
            width: 60%;
            height: auto;
            // animation-name: bar;
            // animation-duration: 5s;
            // animation-iteration-count: infinite;
        }
    }

  
    .left{
        img{
            position: fixed;
            bottom: 2%;
            right: 58%;
            width: 42%;
            height: auto;
            // animation-name: bar-left;
            // animation-duration: 5s;
            // animation-iteration-count: infinite;
        }

    }
    .right{
        img{
            position: fixed;
            bottom: 2%;
            left: 58%;
            width: 42%;
            height: auto;
            // animation-name: bar-right;
            // animation-duration: 5s;
            // animation-iteration-count: infinite;
            z-index: 0;
        }

    }
    .bot-nav{
        z-index: 1000;
        position:absolute;
        img{
            position: fixed;
            bottom: 2%;
            left: 34%;
            width: 32%;
            height: auto;
            // animation-name: bot-nav;
            // animation-duration: 5s;
            // animation-iteration-count: infinite;
            z-index: 1000;
        }
    }
    @keyframes img-ani{
        0%{
            transform: scale(1);
            opacity: 0.8;
        }
        50%{
            transform: scale(1.1);
            opacity: 1;
        }
        100%{
            transform: scale(1);
            opacity: 0.8;
        }

    }
    @keyframes shake{
        0%{
            transform: rotateX(0deg);
            transform: rotateY(0deg);
        }
        25%{
            transform: rotateX(15deg);
            transform: rotateY(15deg);


        }
        50%{
            transform: rotateX(0deg);
            transform: rotateY(0deg);


        }
        75%{
            transform: rotateX(-15deg);
            transform: rotateY(-15deg);


        }
        100%{
            transform: rotateX(0deg);
            transform: rotateY(0deg);


        }
    }

    .img-icon{
        position: fixed;
        bottom: 2%;
        left: 1%;
        width:10%;
        height: auto;
        z-index: 1000;
        img{
            animation-name: img-ani;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            width: 100%;
           
        }
        img:hover{
            cursor: pointer;
            // animation-name: shake;
            animation-duration: 1s;
        }
    }
  
    .xingtian{
        position: fixed;
        top: 2vh;
        left: 2vw;
        img{
            animation-name: title;
            animation-duration: 15s;
            animation-iteration-count: infinite;
            width:20vw;
            height: auto;
        }
    }
    .select{
        position: absolute;
        z-index: 2000;
        top: 10vh;
        width: 30vw;
        left:35vw;
        background-color: rgba(149, 220, 233, 0.685);
    }


 
}
#space{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1500;
    top: 0;
    left: 0;
    canvas{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1500;
    }
}

@media screen and (max-width:500px) {

   

    .container{ 
        position:absolute;
        width: 100%;
        height: 100%;
        .container{ 
            width: 100%;
            height: 100%;
        }
         .xingtian{
            position: fixed;
            top: 0vh;
            left: 10vw;
            z-index: 1000;
            img{
                animation-name: title;
                animation-duration: 15s;
                animation-iteration-count: infinite;
                width:70vw;
                height: auto;
            }
        }
        .about{
            position:absolute;
            z-index: 1000;
            left: 60vw;
            top: 15vh;
            img{
                
               
                width: 36vw;
             
                animation-name: title;
                animation-duration: 15s;
                animation-iteration-count: infinite;
            }
        }
        #space{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1500;
            top: 0;
            left: 0;
            canvas{
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1500;
            }
        }
    }
  
    }