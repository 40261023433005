.container-player{
    position: fixed;
    z-index: 100;
    top: 2rem;left: 1rem;
}
.container-card{
    // background-image: url('https://res.cloudinary.com/dvr397xda/image/upload/v1650923631/cube/tenor_o9dreg.gif');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}